<template>
  <router-view :user="user"/>
</template>

<script>
import {destroyToken, destroyUser, saveUser, saveToken, getToken} from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {reloadMe} from "./core/services/utils.service";
import { destroyPlayedIds, getNightMode, saveNightMode } from "./core/services/jwt.service";
UIkit.use(Icons);

export default {
  name: "App",
  data() {
    return {
      user: null,
      nightMode: false
    }
  },
  mounted() {
    destroyPlayedIds();
    this.fetchUser();
    this.handleAppHeight();
    this.loadNightMode();
    this.listenEvents();
  },
  methods: {
    getMe() {
      let query = `query {
        me {
          id
          username
          email
          profile_id
          can_upload
          can_update_lyric
          can_update_thumbnail
          can_update_avatar
          can_manage_sheet
          can_manage_comment
          can_manage_thumbnail
          can_manage_upload
          can_manage_document
          can_manage_people
          can_manage_song
          can_manage_tag
          can_mark_hq
          unread
          is_banned
          allow_comment
          allow_comment_until
          avatar {
            url
          }
          playlists(first: 100, orderBy: [{column: "id", order: DESC}]) {
            data {
              id
              title
              slug
              thumbnail {
                url
              }
            }
          }
          recentSearches(first: 10, orderBy: [{column: "created_at", order: DESC}, {column: "weight", order: ASC}]) {
            data {
              weight
              object {
                __typename
                ... on Song {
                  id
                  title
                  slug
                  views
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Folk {
                  id
                  title
                  slug
                  views
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Instrumental {
                  id
                  title
                  slug
                  views
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Karaoke {
                  id
                  title
                  slug
                  views
                  thumbnail {
                    url
                  }
                  users(first: 10) {
                    data {
                      username
                      id
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Poem {
                  id
                  title
                  slug
                  views
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Composer {
                  title
                  slug
                  avatar {
                    url
                  }
                }
                ... on Artist {
                  title
                  slug
                  avatar {
                    url
                  }
                }
                ... on Recomposer {
                  title
                  slug
                  avatar {
                    url
                  }
                }
                ... on Poet {
                  title
                  slug
                  avatar {
                    url
                  }
                }
                ... on Document {
                  id
                  title
                  slug
                  type
                  thumbnail {
                    url
                  }
                }
              }
            }
          }
          recentListens(first: 5, orderBy: [{column: "created_at", order: DESC}]) {
            data {
              object {
                __typename
                ... on Song {
                  id
                  title
                  slug
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Folk {
                  id
                  title
                  slug
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Instrumental {
                  id
                  title
                  slug
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Karaoke {
                  id
                  title
                  slug
                  thumbnail {
                    url
                  }
                  users(first: 10) {
                    data {
                      username
                      id
                      avatar {
                        url
                      }
                    }
                  }
                }
                ... on Poem {
                  id
                  title
                  slug
                  thumbnail {
                    url
                  }
                  artists(first: 10) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          roles {
            id
            name
            alias
            slug
            userRolePivot {
              role
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.is_banned === "N") {
              this.user = data.data.me;
              this.user.last_fetch = Math.floor(Date.now() / 1000);
              saveUser(this.user);
              reloadMe();
            } else {
              destroyToken();
              destroyUser();
              this.user = null;
            }
          })
          .catch((response) => {
            console.log(response);
            this.user = null;
          });
    },
    loginByGoogle(token) {
      let query = `mutation($token: String!) {
        loginByGoogle(token: $token) {
          access_token
        }
      }`;

      ApiService.graphql(query, {token: token})
          .then(({data}) => {
            if (data.data && data.data.loginByGoogle && data.data.loginByGoogle.access_token) {
              saveToken(data.data.loginByGoogle.access_token);
              if (window.location.href.indexOf('login') > -1 || window.location.href.indexOf('register') > -1) {
                window.location = '/';
              } else if (this.$route.query.next) {
                window.location = this.$route.query.next;
              } else {
                location.reload(true);
              }
            }
          })
          .catch((response) => {
            console.log(response);
            this.user = null;
          });
    },
    fetchUser() {
      let token = getToken();
      if (token) {
        this.getMe();
      }
    },
    loadNightMode() {
      this.nightMode = getNightMode();
    },
    handleAppHeight() {
      const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      }
      window.addEventListener('resize', appHeight);
      appHeight();
    },
    listenEvents() {
      window.addEventListener("message", (event) => {
        if (event.data.action === "nightMode") {
          this.nightMode = !this.nightMode;
        }

        if (event.data.action === "updateMe") {
          this.getMe();
        }

        if (event.data.action === "onSignIn") {
          if (event.data.value.type == "google") {
            this.loginByGoogle(event.data.value.token);
          }
        }
      }, false);
    }
  },
  watch: {
    nightMode: {
      handler(newVal) {
        saveNightMode(newVal);
        if (newVal) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      }
    }
  }
}
</script>
