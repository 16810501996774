import axios from "axios";
import {getToken} from "@/core/services/jwt.service";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

axiosInstance.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  get(resource, params) {
    return axiosInstance.get(resource, {params: params}).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axiosInstance.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axiosInstance.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axiosInstance.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axiosInstance.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @returns {*}
   * @param query
   * @param variables
   * @param cancelToken
   */
  graphql(query, variables, cancelToken = null) {
    return axiosInstance.post(`graphql`, {
      query: query,
      variables: variables
    }, {cancelToken: cancelToken});
  },

  uploadMusic(file, context, config = {}) {
    let formData = new FormData();

    formData.append(
      "file",
      file
    );

    config.headers = {
      "Content-Type": "multipart/form-data"
    };

    return axiosInstance.post(process.env.VUE_APP_MP3_API_URL + `/upload?context=` + context, formData, config);
  },

  uploadImage(file, context, config = {}) {
    let formData = new FormData();

    formData.append(
      "file",
      file
    );

    config.headers = {
      "Content-Type": "multipart/form-data"
    };

    return axiosInstance.post(process.env.VUE_APP_IMG_API_URL + `/upload?context=` + context, formData, config);
  },

  uploadTmp(file, path, config = {}) {
    let formData = new FormData();

    formData.append(
      "file",
      file
    );

    config.headers = {
      "Content-Type": "multipart/form-data"
    };

    return axiosInstance.post(process.env.VUE_APP_IMG_API_URL + `/uploadTmp?path=` + path, formData, config);
  }

  
};

export default ApiService;
