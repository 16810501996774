import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import("@/components/Layout"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/components/home/Index")
      },
      {
        path: "/bcdcnt",
        name: "BcdcntList",
        component: () => import("@/components/bcdcnt/BcdcntList")
      },
      {
        path: "/thanh-vien-hat",
        name: "KaraokeList",
        component: () => import("@/components/karaoke/KaraokeList")
      },
      {
        path: "/khi-nhac",
        name: "InstrumentalList",
        component: () => import("@/components/instrumental/InstrumentalList")
      },
      {
        path: "/dan-ca",
        name: "FolkList",
        component: () => import("@/components/folk/FolkList")
      },
      {
        path: "/dan-ca/the-loai",
        name: "FcatList",
        component: () => import("@/components/folk/FcatList")
      },
      {
        path: "/dan-ca/lan-dieu",
        name: "MelodyList",
        component: () => import("@/components/folk/MelodyList")
      },
      {
        path: "/tieng-tho",
        name: "PoemList",
        component: () => import("@/components/poem/PoemList")
      },
      {
        path: "/dan-ca/:slug([a-zA-Z\\-]+)",
        name: "FcatDetail",
        component: () => import("@/components/folk/FcatDetail")
      },
      {
        path: "/lan-dieu/:slug([a-zA-Z\\-]+)",
        name: "MelodyDetail",
        component: () => import("@/components/folk/MelodyDetail")
      },
      {
        path: "/:prefix(bai-hat|dan-ca|khi-nhac|thanh-vien-hat|tieng-tho)/:slug-:id(\\d+).html",
        name: "SongDetail",
        component: () => import("@/components/song/SongDetail")
      },
      {
        path: "/:prefix(nghe-si|nhac-si|soan-gia|nha-tho)/:slug",
        name: "PeopleDetail",
        component: () => import("@/components/people/PeopleDetail")
      },
      {
        path: "/sheet",
        name: "SheetList",
        component: () => import("@/components/sheet/SheetList")
      },
      {
        path: "/sheet/:slug-:id(\\d+).html",
        name: "SheetDetail",
        component: () => import("@/components/sheet/SheetDetail")
      },
      {
        path: "/binh-luan",
        name: "CommentList",
        component: () => import("@/components/comment/CommentList")
      },
      {
        path: "/playlists",
        name: "PlaylistList",
        component: () => import("@/components/playlist/PlaylistList")
      },
      {
        path: "/tu-lieu",
        name: "DocumentList",
        component: () => import("@/components/document/DocumentList")
      },
      {
        path: "/tu-lieu/:slug-:id(\\d+).html",
        name: "DocumentDetail",
        component: () => import("@/components/document/DocumentDetail")
      },
      {
        path: "/dien-dan",
        name: "ForumIndex",
        component: () => import("@/components/discussion/ForumIndex")
      },
      {
        path: "/my-discussions",
        name: "MyDiscussions",
        component: () => import("@/components/discussion/MyDiscussions")
      },
      {
        path: "/forum/:slug-:id(\\d+).html",
        name: "ForumDetail",
        component: () => import("@/components/discussion/ForumDetail")
      },
      {
        path: "/discussion/add",
        name: "DiscussionAdd",
        component: () => import("@/components/discussion/DiscussionAdd")
      },
      {
        path: "/discussion/edit/:id(\\d+)",
        name: "DiscussionEdit",
        component: () => import("@/components/discussion/DiscussionEdit")
      },
      {
        path: "/ticket",
        name: "TicketList",
        component: () => import("@/components/ticket/TicketList")
      },
      {
        path: "/ticket/:id(\\d+)",
        name: "TicketDetail",
        component: () => import("@/components/ticket/TicketDetail")
      },
      {
        path: "/ticket/add",
        name: "TicketAdd",
        component: () => import("@/components/ticket/TicketAdd")
      },
      {
        path: "/ticket/edit/:id",
        name: "TicketEdit",
        component: () => import("@/components/ticket/TicketEdit")
      },
      {
        path: "/user/:id(\\d+)",
        name: "UserDetail",
        component: () => import("@/components/user/UserDetail")
      },
      {
        path: "/playlist/:slug-:id(\\d+).html",
        name: "PlaylistDetail",
        component: () => import("@/components/playlist/PlaylistDetail")
      },
      {
        path: "/playlist/edit/:id",
        name: "PlaylistEdit",
        component: () => import("@/components/playlist/PlaylistEdit")
      },
      {
        path: "/:prefix(nghe-si|nhac-si|nha-tho|soan-gia)",
        name: "PeopleList",
        component: () => import("@/components/people/PeopleList")
      },
      {
        path: "/:prefix(nhac-si)/:type(viet-nam|nuoc-ngoai)",
        name: "ComposerList",
        component: () => import("@/components/people/PeopleList")
      },
      {
        path: "/:prefix(nghe-si)/:type(viet-nam|nuoc-ngoai)",
        name: "ArtistList",
        component: () => import("@/components/people/PeopleList")
      },
      {
        path: "/thao-luan/:slug-:id(\\d+).html",
        name: "DiscussionDetail",
        component: () => import("@/components/discussion/DiscussionDetail")
      },
      {
        path: "/tag/:name",
        name: "TagDetail",
        component: () => import("@/components/tag/TagDetail")
      },
      {
        path: "/tag/:name/play",
        name: "TagListen",
        component: () => import("@/components/tag/TagListen")
      },
      {
        path: "/my-playlist",
        name: "MyPlaylist",
        component: () => import("@/components/playlist/MyPlaylist")
      },
      {
        path: "/my-account",
        name: "MyAccount",
        component: () => import("@/components/user/MyAccount")
      },
      {
        path: "/my-gifts",
        name: "MyGifts",
        component: () => import("@/components/my/MyGifts")
      },
      {
        path: "/notifications",
        name: "NotificationList",
        component: () => import("@/components/notification/NotificationList")
      },
      {
        path: "/upload",
        name: "UploadList",
        component: () => import("@/components/upload/UploadList")
      },
      {
        path: "/upload/:prefix(tan-nhac|dan-ca|khi-nhac|thanh-vien-hat|tieng-tho|thay-the-ban-thu|tu-lieu-am-thanh|tu-lieu-hinh-anh|tu-lieu-bai-viet|sheet)",
        name: "UploadAdd",
        component: () => import("@/components/upload/UploadAdd")
      },
      {
        path: "/upload/edit/:id",
        name: "UploadEdit",
        component: () => import("@/components/upload/UploadEdit")
      },
      {
        path: "/van-hanh",
        name: "RoleIndex",
        component: () => import("@/components/role/RoleIndex")
      },
      {
        path: "/nhan-su/:slug([a-z0-9-]+)",
        name: "RoleDetail",
        component: () => import("@/components/role/RoleDetail")
      },
      {
        path: "/:slug(gioi-thieu|gop-y|yeu-cau|quy-dinh|ban-quyen|quang-cao|dieu-khoan|bao-mat|tieu-chi|ung-ho|tai-tro)",
        name: "PageDetail",
        component: () => import("@/components/page/PageDetail")
      },
      {
        path: "/admin/comments",
        name: "AdminCommentList",
        component: () => import("@/components/admin/comment/CommentList")
      },
      {
        path: "/admin/users",
        name: "AdminUserList",
        component: () => import("@/components/admin/user/UserList")
      },
      {
        path: "/admin/uploads",
        name: "AdminUploadList",
        component: () => import("@/components/admin/upload/UploadList")
      },
      {
        path: "/admin/songs",
        name: "AdminSongList",
        component: () => import("@/components/admin/song/SongList")
      },
      {
        path: "/admin/songs/add",
        name: "AdminSongAdd",
        component: () => import("@/components/admin/song/SongAdd")
      },
      {
        path: "/admin/songs/edit/:id",
        name: "AdminSongEdit",
        component: () => import("@/components/admin/song/SongEdit")
      },
      {
        path: "/admin/folks",
        name: "AdminFolkList",
        component: () => import("@/components/admin/folk/FolkList")
      },
      {
        path: "/admin/folks/add",
        name: "AdminFolkAdd",
        component: () => import("@/components/admin/folk/FolkAdd")
      },
      {
        path: "/admin/folks/edit/:id",
        name: "AdminFolkEdit",
        component: () => import("@/components/admin/folk/FolkEdit")
      },
      {
        path: "/admin/instrumentals",
        name: "AdminInstrumentalList",
        component: () => import("@/components/admin/instrumental/InstrumentalList")
      },
      {
        path: "/admin/instrumentals/add",
        name: "AdminInstrumentalAdd",
        component: () => import("@/components/admin/instrumental/InstrumentalAdd")
      },
      {
        path: "/admin/instrumentals/edit/:id",
        name: "AdminInstrumentalEdit",
        component: () => import("@/components/admin/instrumental/InstrumentalEdit")
      },
      {
        path: "/admin/karaokes",
        name: "AdminKaraokeList",
        component: () => import("@/components/admin/karaoke/KaraokeList")
      },
      {
        path: "/admin/karaokes/add",
        name: "AdminKaraokeAdd",
        component: () => import("@/components/admin/karaoke/KaraokeAdd")
      },
      {
        path: "/admin/karaokes/edit/:id",
        name: "AdminKaraokeEdit",
        component: () => import("@/components/admin/karaoke/KaraokeEdit")
      },
      {
        path: "/admin/poems",
        name: "AdminPoemList",
        component: () => import("@/components/admin/poem/PoemList")
      },
      {
        path: "/admin/poems/add",
        name: "AdminPoemAdd",
        component: () => import("@/components/admin/poem/PoemAdd")
      },
      {
        path: "/admin/poems/edit/:id",
        name: "AdminPoemEdit",
        component: () => import("@/components/admin/poem/PoemEdit")
      },
      {
        path: "/admin/composers",
        name: "AdminComposerList",
        component: () => import("@/components/admin/composer/ComposerList")
      },
      {
        path: "/admin/composers/add",
        name: "AdminComposerAdd",
        component: () => import("@/components/admin/composer/ComposerAdd")
      },
      {
        path: "/admin/composers/edit/:id",
        name: "AdminComposerEdit",
        component: () => import("@/components/admin/composer/ComposerEdit")
      },
      {
        path: "/admin/artists",
        name: "AdminArtistList",
        component: () => import("@/components/admin/artist/ArtistList")
      },
      {
        path: "/admin/artists/add",
        name: "AdminArtistAdd",
        component: () => import("@/components/admin/artist/ArtistAdd")
      },
      {
        path: "/admin/artists/edit/:id",
        name: "AdminArtistEdit",
        component: () => import("@/components/admin/artist/ArtistEdit")
      },
      {
        path: "/admin/recomposers",
        name: "AdminRecomposerList",
        component: () => import("@/components/admin/recomposer/RecomposerList")
      },
      {
        path: "/admin/recomposers/add",
        name: "AdminRecomposerAdd",
        component: () => import("@/components/admin/recomposer/RecomposerAdd")
      },
      {
        path: "/admin/recomposers/edit/:id",
        name: "AdminRecomposerEdit",
        component: () => import("@/components/admin/recomposer/RecomposerEdit")
      },
      {
        path: "/admin/poets",
        name: "AdminPoetList",
        component: () => import("@/components/admin/poet/PoetList")
      },
      {
        path: "/admin/poets/add",
        name: "AdminPoetAdd",
        component: () => import("@/components/admin/poet/PoetAdd")
      },
      {
        path: "/admin/poets/edit/:id",
        name: "AdminPoetEdit",
        component: () => import("@/components/admin/poet/PoetEdit")
      },
      {
        path: "/admin/documents",
        name: "AdminDocumentList",
        component: () => import("@/components/admin/document/DocumentList")
      },
      {
        path: "/admin/documents/add",
        name: "AdminDocumentAdd",
        component: () => import("@/components/admin/document/DocumentAdd")
      },
      {
        path: "/admin/documents/edit/:id",
        name: "AdminDocumentEdit",
        component: () => import("@/components/admin/document/DocumentEdit")
      },
      {
        path: "/admin/sheets",
        name: "AdminSheetList",
        component: () => import("@/components/admin/sheet/SheetList")
      },
      {
        path: "admin/tickets",
        name: "AdminTicketList",
        component: () => import("@/components/admin/ticket/TicketList")
      },
      {
        path: "/admin/sheets/add",
        name: "AdminSheetAdd",
        component: () => import("@/components/admin/sheet/SheetAdd")
      },
      {
        path: "/admin/sheets/edit/:id",
        name: "AdminSheetEdit",
        component: () => import("@/components/admin/sheet/SheetEdit")
      },
      {
        path: "/admin/roles",
        name: "AdminRoleList",
        component: () => import("@/components/admin/role/RoleList")
      },
      {
        path: "/admin/roles/add",
        name: "AdminRoleAdd",
        component: () => import("@/components/admin/role/RoleAdd")
      },
      {
        path: "/admin/roles/edit/:id",
        name: "AdminRoleEdit",
        component: () => import("@/components/admin/role/RoleEdit")
      },
      {
        path: "/admin/tags",
        name: "AdminTagList",
        component: () => import("@/components/admin/tag/TagList")
      },
      {
        path: "/admin/changeUsernameRequests",
        name: "AdminChangeUsernameRequestList",
        component: () => import("@/components/admin/changeUsernameRequest/ChangeUsernameRequestList")
      },
      {
        path: "/admin/tool/mergeSong",
        name: "AdminToolMergeSong",
        component: () => import("@/components/admin/tool/MergeSong")
      },
      {
        path: "/admin/tool/mergePeople",
        name: "AdminToolMergePeople",
        component: () => import("@/components/admin/tool/MergePeople")
      },
      {
        path: "/admin/tool/swapSong",
        name: "AdminToolSwapSong",
        component: () => import("@/components/admin/tool/SwapSong")
      },
      {
        path: "/admin/tool/changeSongType",
        name: "AdminChangeSongType",
        component: () => import("@/components/admin/tool/ChangeSongType")
      },
      {
        path: "/admin/statistic",
        name: "AdminStatistic",
        component: () => import("@/components/admin/statistic/Overall")
      },
      {
        path: "/:catchAll(.*)",
        name: "404",
        component: () => import("@/components/404")
      }
    ]
  },
  {
    path: '/',
    component: () => import("@/components/LayoutGuest"),
    children: [
      {
        path: "/test",
        name: "Test",
        component: () => import("@/components/Test")
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/components/Login")
      },
      {
        path: "/register",
        name: "Register",
        component: () => import("@/components/Register")
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import("@/components/ForgotPassword"),
      },
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import("@/components/ChangePassword"),
      },
      {
        path: '/validate',
        name: 'ValidateCode',
        component: () => import("@/components/ValidateCode"),
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import("@/components/Logout"),
      }
    ]
  },
  {
    path: '/',
    component: () => import("@/components/LayoutNone"),
    children: [
      {
        path: "/tu-lieu/full/:slug-:id(\\d+).html",
        name: "DocumentDetailFull",
        component: () => import("@/components/document/DocumentDetailFull")
      }
    ]
  },
  {
    path: '/',
    component: () => import("@/components/LayoutNone"),
    children: [
      {
        path: "/tu-lieu/xem-truoc/:slug-:id(\\d+).html",
        name: "DocumentDetailPreview",
        component: () => import("@/components/document/DocumentDetailFull")
      }
    ]
  },
  {
    path: '/',
    component: () => import("@/components/LayoutFluid"),
    children: [
      {
        path: "/upload/:id(\\d+)",
        name: "UploadDetail",
        component: () => import("@/components/upload/UploadDetail")
      },
      {
        path: "/vov",
        name: "VovDailyList",
        component: () => import("@/components/vov/VovDailyList")
      },
      {
        path: "/vov/daily/:id",
        name: "VovDailyDetail",
        component: () => import("@/components/vov/VovDailyDetail")
      },
      {
        path: "/vov/:slug-:id(\\d+).html",
        name: "VovProgramDetail",
        component: () => import("@/components/vov/VovProgramDetail")
      },
      {
        path: "/tro-giup/mat-du-lieu",
        name: "EmptyFile",
        component: () => import("@/components/empty/EmptyFile")
      },
      {
        path: "/tro-giup/loi-bai-hat",
        name: "EmptyLyric",
        component: () => import("@/components/empty/EmptyLyric")
      },
      {
        path: "/tro-giup/anh-minh-hoa",
        name: "EmptyThumbnail",
        component: () => import("@/components/empty/EmptyThumbnail")
      },
      {
        path: "/tro-giup/tag-bai-hat",
        name: "EmptyTagSong",
        component: () => import("@/components/empty/EmptyTagSong")
      }
    ]
  },
  {
    path: '/',
    component: () => import("@/components/LayoutFluidNoHeaderFooter"),
    children: [
      {
        path: "/admin/tool/importSheetFromPdf",
        name: "AdminToolImportSheetFromPdf",
        component: () => import("@/components/admin/tool/ImportSheetFromPdf")
      }
    ]
  },
  {
    path: "/embed",
    name: "Embed",
    component: () => import("@/components/Embed")
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

export default router
