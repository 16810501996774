const ID_TOKEN_KEY = "access_token";
const ID_USER_KEY = "user";
const ID_AUTOPLAY_KEY = "autoPlay";
const ID_NIGHTMODE_KEY = "nightMode";
const ID_PLAYED_IDS_KEY = "playedIds";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyUser = () => {
  window.localStorage.removeItem(ID_USER_KEY);
};

export const saveUser = user => {
  window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
};

export const addPlayedId = (id, prefix) => {
  let playedIdsLocal = window.localStorage.getItem(ID_PLAYED_IDS_KEY);
  var playedIdsStruct;
  try {
    playedIdsStruct = JSON.parse(playedIdsLocal);
    if (playedIdsStruct.prefix !== prefix) {
      playedIdsStruct.prefix = prefix;
      playedIdsStruct.ids = [id];
    } else {
      if (playedIdsStruct.ids.indexOf(id) === -1) {
        playedIdsStruct.ids.push(id);
      }
    }
  } catch (e) {
    console.log("Could not decode " + ID_PLAYED_IDS_KEY);
    playedIdsStruct = {
      prefix: prefix,
      ids: [id]
    }
  }
  
  window.localStorage.setItem(ID_PLAYED_IDS_KEY, JSON.stringify(playedIdsStruct));
};

export const destroyPlayedIds = () => {
  window.localStorage.removeItem(ID_PLAYED_IDS_KEY);
};

export const getPlayedIds = () => {
  try {
    return JSON.parse(window.localStorage.getItem(ID_PLAYED_IDS_KEY));
  } catch (e) {
    return null;
  }
};


export const updateUser = (key, value) => {
  let user = getUser();
  if (!user) {
    user = {};
  }
  user[key] = value;
  saveUser(user);
};

export const getUser = () => {
  try {
    return JSON.parse(window.localStorage.getItem(ID_USER_KEY));
  } catch (e) {
    return null;
  }
};

export const getAutoplay = () => {
  try {
    let autoplay = window.localStorage.getItem(ID_AUTOPLAY_KEY);
    return autoplay === "true";
  } catch (e) {
    return false;
  }
};

export const saveAutoplay = autoplay => {
  window.localStorage.setItem(ID_AUTOPLAY_KEY, autoplay);
};

export const getNightMode = () => {
  try {
    let nightMode = window.localStorage.getItem(ID_NIGHTMODE_KEY);
    return nightMode === "true";
  } catch (e) {
    return false;
  }
};

export const saveNightMode = nightMode => {
  window.localStorage.setItem(ID_NIGHTMODE_KEY, nightMode);
};

export default { getToken, saveToken, destroyToken, getUser, saveUser, getAutoplay, saveAutoplay, getNightMode, saveNightMode };
